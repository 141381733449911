<template>

    <div>
        <div class="text-center m-2.5 text-xl font-medium text-black">MeterValidatorBot</div>
        <div class="prose">
          <p class="py-2 text-gray-500">I am a community created project that will monitor your node and message you directly via Telegram if there are issues.</p>
          <p class="py-2 text-gray-500">Click the link below to sign a transaction with your node key to confirm ownership of your node.</p>
        </div>

        <div v-if="messageForView">
          <p v-html="messageForView" class="py-2 text-gray-900"></p>
        </div>

        <div class="text-center">
          <div v-if="metamaskInstalled">
            <button v-if="signVisible" @click="triggerMetaMaskSigning" type="button" class="py-2 px-4 m-3.5 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none" tabindex="-1">
              Sign a Message
            </button>
            <button v-else  @click="connectToSite" type="button" class="py-2 px-4 m-3.5 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none" tabindex="-1">
              Connect your MetaMask
            </button>
          </div>
          <div v-else>
            <p class="py-2 text-gray-500">You must have Metamask installed on a compatible browser to link with the bot. If you believe this is an error, please refresh the page.</p>
          </div>
        </div>

    </div>
</template>

<script>

import axios from 'axios';
import web3 from 'web3'
import detectEthereumProvider from "@metamask/detect-provider";

export default {
  name: "metamask-start",
  data:() => ({
    // a computed getter
    metamaskInstalled: false,
    signVisible: window.ethereum !== undefined && window.ethereum.selectedAddress !== null && window.ethereum.chainId == "0x52",
    messageForView: false
  }),
  async mounted() {
    this.metamaskInstalled = await this.metaMaskInstalled();

    window.ethereum.on('networkChanged', this.handleChainChanged)
    window.ethereum.autoRefreshOnNetworkChange = false;
/*
    window.ethereum.on('networkChanged', this.handleChainChanged)
*/
  },
  computed:() => ({
    validToken() {
      return this.$route.params.hash;
    }
  }),
  methods: {
    handleChainChanged(chainId) {
      if(window.ethereum.selectedAddress !== null && chainId == 82) {
        this.signVisible = true;
        return;
      }

      this.signVisible = false;
    },
    async metaMaskInstalled() {

      const provider = await detectEthereumProvider()
      if (provider) {

        console.log('Ethereum successfully detected!')

        // Legacy providers may only have ethereum.sendAsync
        await provider.request({
          method: 'eth_chainId'
        })

        let isMetaMask = await window.ethereum.isMetaMask
        return isMetaMask

      } else {

        // if the provider is not detected, detectEthereumProvider resolves to null
        console.error('Please install MetaMask!')

        return false;
      }

    },
    async connectToSite() {

      try {
        let signedIn = await window.ethereum.request({method: 'eth_requestAccounts'})

        if(signedIn) {

          if(window.ethereum.chainId == "0x52") {
            console.log("setting");
            this.signVisible = true;
            return;
          }

          var switchNetwork;

          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x52',
                  chainName: 'Meter Mainnet',
                  nativeCurrency: {
                    name: 'Meter',
                    symbol: 'MTR', // 2-6 characters long
                    decimals: 18
                  },
                  rpcUrls: ['https://rpc.meter.io'],
                  blockExplorerUrls: ['https://scan.meter.io']
                }
              ],
            });
          } catch (addError) {
            if(addError.code === 4001) {
              this.messageForView = "User denied action to add network. Please accept or manually add the Meter Mainnet network to your MetaMask.";
            } else {
              this.messageForView = "[A] An error occurred. Please highlight this issue in the Meter Validators Telegram group.";
            }
          }


          // begin new
          try {
            switchNetwork = await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x52' }],
            });

          } catch (switchError) {
            console.log("Issue switching network. This is happening on mobile");
            if(switchError.code === -32601 || switchError.code === 4001) {
              this.messageForView = "User denied action to switch network. Please switch to the Meter Mainnet network in MetaMask.";
              console.log("Cancelled switching network code is -3601 for mobile and 4001 for desktop");
              this.signVisible = false;
              return;
            }
            // do nothing with this error for now.
            // handle other "switch" errors
          }


          if(switchNetwork == null) {
            this.messageForView = '';
            this.signVisible = true;
          }

        }
      } catch(error) {
        console.log(error)
      }

    },
    async triggerMetaMaskSigning() {

      console.log('is it visible');
      console.log(this.signVisible);
      // console.log(window.ethereum.selectedAddress);
      import('eth-sig-util');
      import('axios');

      console.log(window.ethereum);

      console.log(this.$route.params.hash);
      var hash = this.$route.params.hash;

      console.log(hash);

      try {
        let signedTransaction = await window.ethereum.request({
          id: 1,
          method: 'personal_sign',
          params: [web3.utils.fromUtf8("Validate Node Ownership"), window.ethereum.selectedAddress]
        })

        if(signedTransaction) {

          var sig = signedTransaction;

          try {
            await
                axios.post(process.env.VUE_APP_EXPRESS_ENDPOINT + "authenticate/" , { params: { sig, hash }})
                    .then((response) => {
                      var responseData = response.data
                      if(responseData.status == "error") {
                        switch (responseData.errorCode) {
                          case 1:
                            this.messageForView = "You have already linked this node/address with an account."
                                break
                          case 2:
                            this.messageForView = "Invalid token received. Please generate a new token by messaging me on telegram with <code>/start</code>"
                                break
                          case 3:
                            this.messageForView = responseData.message
                                break
                          case 4:
                            this.messageForView = "This address is not linked with a node. Please ensure you've selected the correct address in MetaMask and have allowed this website access each relevant one. If you believe this is an error please highlight this issue in the Meter Validators group."
                                break
                          case 5:
                            this.messageForView = "There was an error communicating with the API. Please highlight this issue in the Meter Validators group."
                        }
                      } else {
                        this.messageForView = "You have successfully linked your node with your Telegram account. I will try to let you know if there are any issues with your node."
                      }
                    })
                    .catch(error => {
                      console.log(error)
                      this.messageForView = "A serious error occurred. Please highlight this issue in the Meter Validators Node chat."
                    })

          } catch(validationError) {
            console.log(validationError);
          }

        }
      } catch(error){
        if(error.code == 4001) {
          this.messageForView = "User denied message signature. A signature is required for linking your node."
        }
      }
    },
  },
  toHex: function (s) {
    var hex = '';
    for (var i = 0; i < s.length; i++) {
      hex += '' + s.charCodeAt(i).toString(16);
    }
    return `0x${hex}`;
  }
}



</script>

<style>
code {
  color: #242729;
  background-color: lightgray;
  padding: 2px 4px;
}
</style>