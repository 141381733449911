<template>

  <div class="px-6 py-12">
    <div class="text-center">
      <img class="align-middle block mx-auto h-48 m-3.5 rounded-full" src="./assets/meter-validator-bot-logo.png" alt="MeterValidatorBot Logo">
    </div>
    <div class="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md">
      <router-view />
    </div>
    <footer class="p-2 max-w-sm mx-auto">
      <div class="flex space-x-4">
        <div class="flex-none rounded-md bg-emerald-500 text-white font-semibold flex items-center justify-center py-3 px-2">
          <a href="https://meter.io" id="meter-logo" class="block mx-auto h-24 rounded-full sm:mx-0 sm:flex-shrink-0">
            <img class="" src="./assets/meter-io.png" alt="Meter logo" id="meter-logo-footer">
          </a>
        </div>
        <div class="flex-1 rounded-md bg-emerald-300 text-white font-semibold flex items-center justify-center py-3 px-0">
          <div class="space-y-0.5">
            <p class="text-gray-500 text-xs">This bot is a community project that is not directly affiliated with Meter. If you want to become a validator, check out the Meter Node Creator script on <a href="https://github.com/daveodwyer/meter-node-creator">GitHub</a></p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style>

  #meter-logo {
    display: inline-block;
    height: 35px;
    width: 35px;
  }

  #meter-logo-footer {
    width: 35px;
    opacity: 1;
    -webkit-filter: contrast(4%) grayscale(0%);
    filter: contrast(4%) grayscale(0%);
  }
</style>