import { createWebHistory, createRouter } from "vue-router";
import MetaMaskStart from "../components/metamask-buttons.vue";
import NoHashPresent from "../components/no-hash-present.vue";

const routes = [
    {
        path: "/",
        name: "no-hash-present",
        component: NoHashPresent,
    },
    {
        path: "/:hash",
        name: "metamask-buttons",
        component: MetaMaskStart,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;