<template>

      <div>
        <div class="text-center m-2.5 text-xl font-medium text-black">MeterValidatorBot</div>
        <div class="prose">
          <p class="py-2 text-gray-500">I am a community created project that will try to message you directly via Telegram if there are any issues with your node.</p>
          <p class="py-2 text-gray-500">Click the link below to generate a registration link via your Telegram account. Simply message me <code>/start</code> and I will generate a link for you.</p>
        </div>

        <div class="text-center">
          <a v-bind:href="telegramBotLink" type="button" class="py-2 px-4 m-3.5 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none">
            Open in Telegram
          </a>
        </div>
      </div>
</template>

<script>

export default {
  name: "no-hash-present",
  data:() => ({
    // a computed getter
    metamaskInstalled: window.ethereum !== undefined,
    signVisible: window.ethereum !== undefined && window.ethereum.selectedAddress !== null,
    messageForView: false,
    telegramBotLink: process.env.VUE_APP_TELEGRAM_BOT_LINK
  })
}
</script>

<style scoped>

  #app {
    height: 100%;
  }

  code {
    color: #242729;
    background-color: lightgray;
    padding: 2px 4px;
  }
</style>